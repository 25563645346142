import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  Form,
  ModalTitle,
  Progress,
  TradingIsClosed,
} from '@trader/components';
import {
  BuyButton,
  ConfirmButton,
  TradingRealTimeUpdatesInitializer,
} from '@trader/trading';
import { useI18next } from '@trader/services';
import { calcPositionPl, calculateSpread } from '@trader/utils';
import { IInitialTradingFormValues } from '@trader/types';

import {
  IPositionMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { Protection } from '../createOrder/components/Protection';
import { useAddProtectionFormValidation } from './formValidation';

import * as Styled from './styled';

const AddProtection: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { validationSchema } = useAddProtectionFormValidation();

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const positionId = store.ui.modal.options.get('positionId');

  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const takeProfit = metric?.takeProfit?.limitPrice || undefined;
  const stopLoss = metric?.stopLoss?.stopPrice || undefined;
  const amount = metric?.quantity || undefined;

  const defaultFormValues = useGetTradingDefaultFormValues('modifyOrder', {
    symbol: instrument.symbol,
    side: metric?.side,
    takeProfit,
    stopLoss,
    amount,
  });

  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const addProtection = (data: IInitialTradingFormValues) => {
    metric?.editPositionAsync.run({
      positionId,
      body: {
        stopPrice: data.isStopLoss ? +data.stopLoss : 0,
        limitPrice: data.isTakeProfit ? +data.takeProfit : 0,
        symbol: instrument.symbol,
      },
    });

    store.ui.modal.update({});
  };

  if (applyConditionThird().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionThird()?.action}
      />
    );
  }

  if (instrument.tradingStatus === 'CLOSE_ONLY') {
    return (
      <Styled.Root>
        <ModalTitle title={translate('COMMON.LABELS.ADD_PROTECTION')} />
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_LIMITED_TO_CLOSE_ONLY_POSITIONS')}
        />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.ADD_PROTECTION')} />
      <Form<IInitialTradingFormValues>
        onSubmit={(_reser, data) => {
          addProtection(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        {({ formState }) => (
          <TradingRealTimeUpdatesInitializer type='modifyOrder'>
            <Styled.Position>
              <TradingModalHeader<IPositionMetricStore>
                type='position'
                metric={{
                  ...metric,
                  pl: calcPositionPl(metric),
                }}
              />
              {!isInvestmentAccProduct && (
                <Styled.ActionButtons>
                  <BuyButton type='modifyOrder' isDisabled isAsk side='Sell' />
                  <Styled.Spread>
                    {calculateSpread({
                      ask: instrument.ask,
                      bid: instrument.bid,
                      pipSize: instrument.pipSize,
                    })}
                  </Styled.Spread>
                  <BuyButton type='modifyOrder' isDisabled side='Buy' />
                </Styled.ActionButtons>
              )}

              <Protection
                type='modifyOrder'
                openPrice={String(metric.openPrice)}
              />
              <ConfirmButton
                isDisabled={!formState.isValid}
                isLoading={metric?.editPositionAsync.inProgress}
                title={
                  metric.strategy?.id
                    ? translate('MULI_BANDS.SWITCH_MODE_POSITION')
                    : translate('COMMON.LABELS.ADD_PROTECTION')
                }
              />
            </Styled.Position>
          </TradingRealTimeUpdatesInitializer>
        )}
      </Form>
      {metric.strategy?.id && (
        <Styled.SwitchStrategyModeTitle>
          {translate('MULI_BANDS.SWITCH_MODE_POSITION_INFO')}
        </Styled.SwitchStrategyModeTitle>
      )}
    </Styled.Root>
  );
});

export const AddProtectionWrapper: React.FC = observer(() => {
  const store = useMst();
  useCloseModalForAlreadyDeletedInstance('positionId');

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const positionId = store.ui.modal.options.get('positionId');
  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  return <AddProtection />;
});
