export const bandStrategy = {
  muliBandsExisting: '/band-strategy/exists',
  muliBands: '/band-strategy',
  deleteOrders: '/band-strategy/cancel-all-orders',
  muliBandsPing: '/band-strategy/ping',
  placeMuliBandsOrder: '/band-strategy/place-order',
  cancelMuliBandsOrder: '/band-strategy/cancel-order',
  editMuliBandsOrder: '/band-strategy/edit-order',
  editMuliBandsPosition: '/band-strategy/edit-position',
  backTestCancel: '/band-strategy/back-test/cancel',
  backTestExists: '/band-strategy/back-test/exists',
} as const;
