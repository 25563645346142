import {
  api,
  IAssetOrderItemsBE,
  IEditOrderParams,
  IGetAssetOrderMetricsParams,
  IOrderItemsBE,
} from '@trader/api';
import { TOrderMetricEntity } from '@trader/store';
import { devLoggerService, SmartLookService } from '@trader/services';
import { hasCopierFunctionality } from '@trader/constants';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { ordersMetricsSchema } from './schemas';
import { returnTypedThis } from '@trader/utils';

export const getOrdersMetricsAsync = createThunk<
  {
    symbol?: IGetAssetOrderMetricsParams['symbol'];
    shouldClearBeforeMerge?: boolean;
  },
  void
>(
  options =>
    async function getOrdersMetrics(this: unknown, _options, _flow) {
      try {
        const { symbol, shouldClearBeforeMerge = false } = options || {};
        const root = getRootInstance();

        const res = symbol
          ? await api.Trading.getAssetOrdersMetrics({ symbol })
          : await api.Trading.getOrdersMetrics();

        const orders = symbol
          ? [(res as IAssetOrderItemsBE).order]
          : (res as IOrderItemsBE).orders;

        const metrics = (orders || []).map(o =>
          o.orderMetrics.map(m => ({
            ...m,
            symbol: o.symbol,
            pipSize: o.pipSize,
            iconUrl: o.iconUrl,
          }))
        );
        const ordersMetrics = metrics.flat(1);
        root.entities.normalizeMerge(
          ordersMetrics,
          ordersMetricsSchema,
          shouldClearBeforeMerge
        );
      } catch (e) {
        devLoggerService.error('Error in getOrdersMetricsAsync', e);
      }
    }
);

export const editOrderAsync = createThunk<IEditOrderParams, void>(
  params =>
    async function editOrder(this: unknown, _options, _flow) {
      const that = returnTypedThis<TOrderMetricEntity>(this);
      const root = getRootInstance();
      const muliBands = root.pages.muliBands;

      if (that?.strategyId) {
        muliBands.editMuliBandsOrderAsync.run({
          orderId: params.orderId,
          price: params.body.price,
          stopLoss: params.body.stopLoss,
          takeProfit: params.body.takeProfit,
        });
      } else {
        if (hasCopierFunctionality) {
          await api.Trading.editChallengeOrder(params, _options);
        } else {
          await api.Trading.editOrder(params, _options);
        }
      }

      SmartLookService.track('Edit_order_event');

      root.ui.modal.close();
    }
);

export const cancelOrderAsync = createThunk<string, void>(
  orderId =>
    async function cancelOrder(this: unknown, _options, _flow) {
      if (hasCopierFunctionality) {
        await api.Trading.cancelChallengeOrder(orderId, _options);
      } else {
        await api.Trading.cancelOrder(orderId, _options);
      }
      SmartLookService.track('Cancel_order_event');
    }
);

export const cancelAllOrdersAsync = createThunk<void, void>(
  () =>
    async function cancelAllOrders(this: unknown, _options, _flow) {
      const root = getRootInstance();

      try {
        if (hasCopierFunctionality) {
          await api.Trading.cancelAllChallengeOrders(_options);
        } else {
          await api.Trading.cancelAllOrders(_options);
        }
        SmartLookService.track('Cancel_all_orders_event');

        root.ui.modal.close();
      } catch (e) {
        devLoggerService.error('catch cancelAllOrdersAsync error', e);
      }
    }
);
